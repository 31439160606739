export const ORDERS_LIST = 'ORDERS/SET-ORDERS-LIST'
export const CLEAR_ORDERS_LIST = 'ORDERS/CLEAR_ORDERS_LIST'
export const SET_FLOATING_VALUE = 'ORDERS/SET_FLOATING_VALUE'
export const SET_DETAILS_VIEW_INFO = 'ORDERS/SET_DETAILS_VIEW_INFO'
export const SET_DETAILS_PRODUCT_QUANTITY = 'ORDERS/SET_DETAILS_PRODUCT_QUANTITY'
export const REMOVE_DETAILS_PRODUCT_QUANTITY = 'ORDERS/REMOVE_DETAILS_PRODUCT_QUANTITY'
export const REMOVE_DETAILS_VIEW_INFO = 'ORDERS/REMOVE_DETAILS_VIEW_INFO'
export const SET_DETAILS_INFO_SELECTED = 'ORDERS/SET_DETAILS_INFO_SELECTED'
export const ADD_SELECTED_ROW = 'ORDERS/ADD_SELECTED_ROW'
export const REMOVE_SELECTED_ROW = 'ORDERS/REMOVE_SELECTED_ROW'
export const CLEAR_SELECTED_ROWS = 'ORDERS/CLEAR_SELECTED_ROWS'
export const SET_ORDERS_CONDITIONS = 'ORDERS/SET_ORDERS_CONDITIONS'
export const CLEAR_ORDERS_CONDITIONS = 'ORDERS/CLEAR_ORDERS_CONDITIONS'
export const SET_ORDERS_SORT_CONDITIONS = 'ORDERS/ORDERS_SORT_CONDITIONS'
export const UPDATE_ORDERS_CONDITIONS = 'ORDERS/UPDATE_ORDERS_CONDITIONS'
export const SET_STATUS_FLOATING_FILTER = 'ORDERS/SET_STATUS_FLOATING_FILTER'
export const SET_DETAILS_VIEW_FULL_SCREEN = 'ORDERS/SET_DETAILS_VIEW_FULL_SCREEN'
export const SET_COUNTRIES = 'ORDER/SET_COUNTRIES'
export const SET_ORDER_DECLARATION_ERROR_LIST = 'ORDER/SET_ORDER_DECLARATION_ERROR_LIST'
export const REMOVE_ORDER_DECLARATION_ERROR = 'ORDER/REMOVE_ORDER_DECLARATION_ERROR'
export const SET_CUSTOM_DECLARATION_ACTIVE = 'ORDER/SET_CUSTOM_DECLARATION_ACTIVE'
export const SET_ORDER_DETAILS_ERROR_LIST = 'ORDER/SET_ORDER_DETAILS_ERROR_LIST'
export const REMOVE_ORDER_DETAILS_ERROR = 'ORDER/REMOVE_ORDER_DETAILS_ERROR'
export const SET_ORDER_DETAILS_ERROR = 'ORDER/SET_ORDER_DETAILS_ERROR'
export const SET_ORDER_DETAILS_ACTIVE = 'ORDER/SET_ORDER_DETAILS_ACTIVE'
export const SET_ORDER_DETAILS_EDIT_SAVE_BUTTON = 'ORDER/SET_ORDER_DETAILS_EDIT_SAVE_BUTTON'
export const REMOVE_ORDER_DETAILS_EDIT_VALIDATION_ERRORS = 'ORDER/REMOVE_ORDER_DETAILS_EDIT_VALIDATION_ERRORS'
export const SET_CALCULATOR_ERROR_LIST = 'ORDER/SET_CALCULATOR_ERROR_LIST'
export const SET_CALCULATOR_ERROR = 'ORDER/SET_CALCULATOR_ERROR'
export const REMOVE_CALCULATOR_ERROR = 'ORDER/REMOVE_CALCULATOR_ERROR'
export const REMOVE_CALCULATOR_ERRORS_LIST = 'ORDER/REMOVE_CALCULATOR_ERRORS_LIST'
export const SET_SERVICES = 'ORDER/SET_SERVICES'
export const SET_CONFIRMATIONS = 'ORDER/SET_CONFIRMATIONS'
export const SET_DELIVERY_PACKAGES = 'ORDER/SET_DELIVERY_PACKAGES'
export const SET_CALCULATOR_PRICES = 'ORDER/SET_CALCULATOR_PRICES'
export const SET_CALCULATOR_LABEL = 'ORDER/SET_CALCULATOR_LABEL'
export const SET_CHECK_PRICE_IN_PROGRESS = 'ORDER/SET_CHECK_PRICE_IN_PROGRESS'
export const SET_CUSTOM_DECLARATION_IN_PROGRESS = 'ORDER/SET_CUSTOM_DECLARATION_IN_PROGRESS'
export const SET_CREATED_DECLARATION_LIST = 'ORDER/SET_CREATED_DECLARATION_LIST'
export const CLEAR_DECLARATION_LIST = 'ORDER/CLEAR_DECLARATION_LIST'
export const SET_CURRENT_PAGE = 'ORDER/SET_CURRENT_PAGE'
export const SET_BAR_CODE_PO_NUMBER = 'ORDER/SET_BAR_CODE_PO_NUMBER'
export const SET_BAR_CODES_LIST = 'ORDER/SET_BAR_CODES_LIST'
export const SET_BAR_CODE_ERRORS_LIST = 'ORDER/SET_BAR_CODE_ERRORS_LIST'
export const REMOVE_BAR_CODE_ERROR = 'ORDER/REMOVE_BAR_CODE_ERROR'
export const SET_MANUAL_ORDER_VALIDATION_ERRORS = 'ORDER/SET_MANUAL_ORDER_VALIDATION_ERRORS'
export const SET_MANUAL_ORDER_ERROR = 'ORDER/SET_MANUAL_ORDER_ERROR'
export const REMOVE_MANUAL_ORDER_VALIDATION_ERRORS = 'ORDER/REMOVE_MANUAL_ORDER_VALIDATION_ERRORS'
export const SET_MANUAL_ORDER_STATUS = 'ORDER/SET_MANUAL_ORDER_STATUS'
export const SET_HS_CODES = 'ORDER/SET_HS_CODES'
export const SET_CUSTOM_DECLARATION_ERROR = 'ORDER/SET_CUSTOM_DECLARATION_ERROR'
export const SET_SHOPS_LIST = 'ORDER/SET_SHOPS_LIST'
export const SET_CURRENT_SHOP = 'ORDER/SET_CURRENT_SHOP'
export const SET_LABELS_LIST = 'ORDER/SET_LABELS_LIST'
export const SET_LABELS_LIST_FOR_TAX_ID_RULES = 'ORDER/SET_LABELS_LIST_FOR_TAX_ID_RULES'
export const LABELS_DATA_LIST_DETAILS = 'ORDER/LABELS_DATA_LIST_DETAILS'
export const CHAT_DATA_LIST_DETAILS = 'ORDER/CHAT_DATA_LIST_DETAILS'
export const SET_CUSTOM_FIELD = 'ORDER/SET_CUSTOM_FIELD'
export const SET_DOWNLOAD_REPORT_STATUS = 'ORDER/SET_DOWNLOAD_REPORT_STATUS'
export const SET_RANGE_REPORT_STATUS = 'ORDER/SET_RANGE_REPORT_STATUS'
export const SET_CURRENT_SHOP_ORDERS_COUNT = 'ORDER/SET_CURRENT_SHOP_ORDERS_COUNT'
export const SET_FILTER_ORDERS_CURRENT_COUNT = 'ORDER/SET_FILTER_ORDERS_CURRENT_COUNT'
export const SET_AUTH_ERROR = 'ORDER/SET_AUTH_ERROR'
export const SET_COMMERCIAL_INVOICES = 'ORDER/SET_COMMERCIAL_INVOICES'
export const ORDER_CONDITION_CHANGED_STATUS = 'ORDER/ORDER_CONDITION_CHANGED_STATUS'
export const CUSTOM_DECLARATION = 'ORDER/CUSTOM_DECLARATION'
export const SET_ORDER_VALIDATION_ERRORS = 'ORDER/SET_ORDER_VALIDATION_ERRORS'
export const SET_ORDERS_LAST_TIMESTAMP = 'ORDER/SET_ORDERS_LAST_TIMESTAMP'
export const SET_AUTO_CREATE_DECLARATION_MANUAL_STATUS = 'ORDER/SET_AUTO_CREATE_DECLARATION_MANUAL_STATUS'
export const SET_CREAT_LABEL_IN_PROGRESS = 'ORDER/SET_CREAT_LABEL_IN_PROGRESS'
export const SET_COMPANY_SHOPS = 'ORDER/SET_COMPANY_SHOPS'
export const SET_SCAN_BAR_CODE_RESPONSE_RECEIVED = 'ORDER/SET_SCAN_BAR_CODE_RESPONSE_RECEIVED'
export const SET_RATES_ERROR_DISPLAYED_ONCE = 'ORDER/SET_RATES_ERROR_DISPLAYED_ONCE'
export const SET_ORDER_COUNT = 'ORDER/SET_ORDER_COUNT'
export const SET_ORDER_ITEMS_PROBLEMS = 'ORDER/SET_ORDER_ITEMS_PROBLEMS'
export const SET_RETURN_LABELS = 'ORDER/SET_RETURN_LABELS'

export const SET_SHIPPING_RULES_LIST = 'SHIPPING_RULES/SET_SHIPPING_RULES_LIST'
export const SET_SHIPPING_RULES_DETAILS = 'SHIPPING_RULES/SET_SHIPPING_RULES_DETAILS'
export const SET_ADD_RULE_ERRORS_LIST = 'SHIPPING_RULES/SET_ADD_RULE_ERRORS_LIST'
export const REMOVE_ADD_RULE_ERRORS_LIST = 'SHIPPING_RULES/REMOVE_ADD_RULE_ERRORS_LIST'
export const PUSH_ADD_RULE_ERROR = 'SHIPPING_RULES/PUSH_ADD_RULE_ERROR'
export const PUSH_SHIPPING_RULE_DETAIL_ARRAY = 'SHIPPING_RULES/PUSH_SHIPPING_RULE_DETAIL_ARRAY'
export const CLEAR_SHIPPING_RULE_DETAIL_ARRAY = 'SHIPPING_RULES/CLEAR_SHIPPING_RULE_DETAIL_ARRAY'

export const SET_CUSTOM_DECLARATION_RULES_LIST = 'CUSTOMS_DECLARATION_RULES/SET_CUSTOM_DECLARATION_RULES_LIST'
export const SET_CUSTOM_DECLARATION_RULES_DETAILS = 'CUSTOMS_DECLARATION_RULES/SET_CUSTOM_DECLARATION_RULES_DETAILS'

export const SET_UPC = 'UPC/SET_UPC'
export const SET_UPC_DATA = 'UPC/SET_UPC_DATA'
export const SET_RETURN_DATA = 'UPC/SET_RETURN_DATA'
export const SET_UPC_PROBLEM_RESPONSE = 'UPC/SET_UPC_PROBLEM_RESPONSE'
export const SET_ORDER_DETAILS_DATA = 'UPC/SET_ORDER_DETAILS_DATA'
export const SET_PRODUCT_QUANTITY = 'UPC/SET_PRODUCT_QUANTITY'
export const REMOVE_PRODUCT_QUANTITY = 'UPC/REMOVE_PRODUCT_QUANTITY'
export const SET_SCALES_DATA_FROM_LOCALHOST = 'UPC/SET_SCALES_DATA_FROM_LOCALHOST'
export const SET_SCALES_DATA_FROM_LOCALHOST_RESPONSE_RECEIVED = 'UPC/SET_SCALES_DATA_FROM_LOCALHOST_RESPONSE_RECEIVED'
export const SET_PREVIOUS_ORDER_TO_LOG = 'UPC/SET_PREVIOUS_ORDER_TO_LOG'
export const SET_LAST_BARCODE_LABEL_ID = 'UPC/SET_LAST_BARCODE_LABEL_ID'

export const SET_SCALES_DATA = 'UPC/SET_SCALES_DATA'
export const SET_RECENT_ACTUAL_SCALES_DATA = 'UPC/SET_RECENT_ACTUAL_SCALES_DATA'
export const SCALES_DATA_MATCH_PREVIOUS_DATA = 'UPC/SCALES_DATA_MATCH_PREVIOUS_DATA'

export const SET_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST = 'CUSTOMS_DECLARATION_RULES/SET_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST'
export const REMOVE_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST = 'CUSTOMS_DECLARATION_RULES/REMOVE_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST'
export const PUSH_ADD_CUSTOMS_DECLARATION_RULE_ERROR = 'CUSTOMS_DECLARATION_RULES/PUSH_ADD_CUSTOMS_DECLARATION_RULE_ERROR'

export const SET_ADD_ROLE_ERRORS_LIST = 'ROLES/SET_ADD_ROLE_ERRORS_LIST'
export const REMOVE_ADD_ROLE_ERRORS_LIST = 'ROLES/REMOVE_ADD_ROLE_ERRORS_LIST'
export const PUSH_ADD_ROLE_ERROR = 'ROLES/PUSH_ADD_ROLE_ERROR'
export const SET_ROLES_LIST = 'ROLES/SET_ROLES_LIST'

export const SET_ROLES_PERMISSIONS_LIST = 'PERMISSIONS/SET_ROLES_PERMISSIONS_LIST'
export const SET_PERMISSIONS_USERS_LIST = 'PERMISSIONS/SET_PERMISSIONS_USERS_LIST'
export const SET_PERMISSION_IS_CHECKED = 'PERMISSIONS/SET_PERMISSION_IS_CHECKED'
export const SET_PERMISSIONS_LIST = 'PERMISSIONS/SET_PERMISSIONS_LIST'

export const SET_ADD_USER_ERRORS_LIST = 'USERS/SET_ADD_USER_ERRORS_LIST'
export const REMOVE_ADD_USER_ERRORS_LIST = 'USERS/REMOVE_ADD_USER_ERRORS_LIST'
export const PUSH_ADD_USER_ERROR = 'USERS/PUSH_ADD_USER_ERROR'
export const SET_USER_LIST = 'USERS/SET_USER_LIST'

export const SET_VERSIONS_LIST = 'USERS/SET_VERSIONS_LIST'
export const SET_USERS_VERSIONS_LIST = 'USERS/SET_USERS_VERSIONS_LIST'

export const SET_USER_SHOPS = 'USERS/SET_USER_SHOPS'

export const SET_WORK_INFO = 'WORK_LOG/SET_WORK_INFO'
export const SET_WORK_TIME_INFO = 'WORK_LOG/SET_WORK_TIME_INFO'
export const SET_WORK_DURATION = 'WORK_LOG/SET_WORK_DURATION'
export const ADD_BREAK_TIME = 'WORK_LOG/ADD_BREAK_TIME'
export const SET_WORK_TIME_RESPONSE_RECEIVED = 'WORK/SET_WORK_TIME_RESPONSE_RECEIVED'


export const SET_RATES_LIST = 'RATES/SET_RATES_LIST'

export const SET_UPLOADING_FILES_LIST = 'UPLOADING_FILES/SET_UPLOADING_FILES_LIST'
export const SET_RETURN_LABELS_ERROR_LOG = 'UPLOADING_FILES/SET_RETURN_LABELS_ERROR_LOG'
export const SET_RETURN_LABELS_SUCCESS_LOG = 'SET_RETURN_LABELS_SUCCESS_LOG'

export const SET_CARRIERS_RATES_LIST = 'CARRIERS_RATES/SET_CARRIERS_RATES_LIST'
export const SET_CARRIERS_RATES_ERROR_LOG_LIST = 'CARRIERS_RATES/SET_CARRIERS_RATES_ERROR_LOG_LIST'
export const SET_CARRIERS_RATES_LOG_LIST = 'CARRIERS_RATES/SET_CARRIERS_RATES_LOG_LIST'

export const SET_CARRIERS_COEFFICIENTS_LOG_LIST = 'CARRIER_COEFFICIENTS/SET_CARRIERS_COEFFICIENTS_LOG_LIST'

export const SET_CARRIERS_COEFFICIENTS_ERROR_LOG_LIST = 'CARRIER_COEFFICIENTS/SET_CARRIERS_COEFFICIENTS_ERROR_LOG_LIST'

export const SET_INVOICES_LIST = 'INVOICE/SET_INVOICES_LIST'
export const SET_INVOICES_INVALID_LIST = 'INVOICE/SET_INVOICES_INVALID_LIST'
export const SET_LAST_CREATED_INVOICE_LINK = 'INVOICE/SET_LAST_CREATED_INVOICE_LINK'
export const SET_INVOICE_LINKS =  'INVOICE/SET_INVOICE_LINKS'

export const SET_INPUT_ERRORS_LIST = 'COMMON/SET_INPUT_ERRORS_LIST'
export const REMOVE_INPUT_ERRORS_LIST = 'COMMON/REMOVE_INPUT_ERRORS_LIST'
export const PUSH_INPUT_ERROR = 'COMMON/PUSH_INPUT_ERROR'

export const SET_COMPANY_ADDRESS_LIST = 'COMPANY_ADDRESS/SET_COMPANY_ADDRESS_LIST'

export const SET_COMPANY_SHOP_ADDRESS_LIST = 'COMPANY_ADDRESS/SET_COMPANY_SHOP_ADDRESS_LIST'

export const SET_SHOP_COEFFICIENTS_LIST = 'SHOP_COEFFICIENTS/SET_SHOP_COEFFICIENTS_LIST'

export const GET_SETTINGS_HS_CODES = 'SETTINGS_HS_CODES/GET_SETTINGS_HS_CODES'
export const SETTINGS_TABLE_HS_CODES = 'SETTINGS_TABLE_HS_CODES'

export const SET_DIM_WEIGHT_LIST = 'SETTINGS_DIM_WEIGHT/SET_DIM_WEIGHT_LIST'
export const SET_DIM_WEIGHT = 'SETTINGS_DIM_WEIGHT/SET_DIM_WEIGHT'
export const SET_DIM_METHOD = 'SETTINGS_DIM_WEIGHT/SET_DIM_METHOD'

export const SET_PROBLEM_TYPE_LIST = 'PROBLEMS_REDUCER/SET_PROBLEM_TYPE_LIST'

export const SET_ERROR_COUNT = 'ERROR/SET_ERROR_COUNT'

export const SET_TAGS_LIST = 'TAGS/SET_TAGS_LIST'

export const CONVEYOR_LINE_GATE_RULES = 'CONVEYOR_LINE_GATE_RULES'