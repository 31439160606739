import SettingsApi from "../../api/SettingsApi/SettingsApi"
import { CONVEYOR_LINE_GATE_RULES } from "../../constants/constants"
import {
  CheckAndNotify,
  CheckAndNotifyErrors,
  LogOutOnAuthError
} from "../orders/ordersReducer"

const initialState = {
  conveyorLineGateRules: []
}

const ConveyorLineGateRulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONVEYOR_LINE_GATE_RULES:
      return {
        ...state,
        conveyorLineGateRules: action.list
      }
    default:
      return state
  }
}

export const setConveyorLineGateRules = (list) => ({
  type: CONVEYOR_LINE_GATE_RULES,
  list
})

export const getConveyorLineGateRules = () => (dispatch, getState) => {
  SettingsApi.getConveyorLineGateRules(getState().auth.AuthToken)
    .then((response) => {
      dispatch(setConveyorLineGateRules(response.data.data))
      CheckAndNotify(response)
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const editConveyorLineGateRule = (props) => (dispatch, getState) => {
  SettingsApi.editConveyorLineGateRule({
    token: getState().auth.AuthToken,
    ...props
  })
    .then((response) => {
      dispatch(getConveyorLineGateRules())
      CheckAndNotify(response)
      props.cb()
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
        props.cb()
      }
    })
}

export const setGateActive = (props) => (dispatch, getState) => {
  SettingsApi.setGateActive({
    token: getState().auth.AuthToken,
    ...props
  })
    .then((response) => {
      dispatch(getConveyorLineGateRules())
      CheckAndNotify(response)
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const createConveyorLineGateRule = (props) => (dispatch, getState) => {
  SettingsApi.createConveyorLineGateRule({
    token: getState().auth.AuthToken,
    ...props
  })
    .then((response) => {
      dispatch(getConveyorLineGateRules())
      CheckAndNotify(response)
      props.cb()
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
        props.cb()
      }
    })
}

export const deleteConveyorLineGateRule =
  (conveyor_lines_gate_rule_id) => (dispatch, getState) => {
    SettingsApi.deleteConveyorLineGateRule({
      token: getState().auth.AuthToken,
      conveyor_lines_gate_rule_id
    })
      .then(() => {
        dispatch(getConveyorLineGateRules())
      })
      .catch((error) => {
        if (error) {
          dispatch(LogOutOnAuthError(error))
          CheckAndNotifyErrors(error)
        }
      })
  }

export default ConveyorLineGateRulesReducer
