import SettingsApi from "../../api/SettingsApi/SettingsApi"

import {
  SETTINGS_TABLE_HS_CODES,
  GET_SETTINGS_HS_CODES
} from "../../constants/constants"
import {
  CheckAndNotify,
  CheckAndNotifyErrors,
  LogOutOnAuthError
} from "../orders/ordersReducer"

const initialState = {
  hsCodesReducer: [],
  tableListHsCodes: []
}

const hsCodesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_HS_CODES:
      return {
        ...state,
        SettingsHsCodes: action.list
          .sort((a, b) => {
            if (!a.hs_code.trim() && b.hs_code.trim()) return -1
            if (a.hs_code.trim() && !b.hs_code.trim()) return 1
            if (!a.hs_code.trim() && !b.hs_code.trim()) return 0
            return a.hs_code.localeCompare(b.hs_code)
          })
          .sort((a, b) => {
            if (a.hs_code.trim() && b.hs_code.trim()) {
              return a.category_name.localeCompare(b.category_name)
            }
          })
      }
    case SETTINGS_TABLE_HS_CODES:
      return {
        ...state,
        tableListHsCodes: action.payload
      }
    default:
      return state
  }
}

export const setSettingsHsCodes = (list) => ({
  type: GET_SETTINGS_HS_CODES,
  list
})
export const setTableListHsCodes = (payload) => ({
  type: SETTINGS_TABLE_HS_CODES,
  payload
})

export const getSettingsHsCodes = (table_id) => (dispatch, getState) => {
  SettingsApi.getSettingsHsCodes(getState().auth.AuthToken, table_id)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(setSettingsHsCodes(response.data.data))
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const getTableListHsCodes = () => (dispatch, getState) => {
  SettingsApi.getTableListHsCodes(getState().auth.AuthToken)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(setTableListHsCodes(response.data.data))
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const updateHsCodes =
  (category_id, hs_code, table_id) => (dispatch, getState) => {
    SettingsApi.updateHsCodes(
      getState().auth.AuthToken,
      category_id,
      hs_code,
      table_id
    )
      .then((response) => {
        CheckAndNotify(response)
        dispatch(setSettingsHsCodes(response.data.data))
        dispatch(getSettingsHsCodes(table_id))
      })
      .catch((error) => {
        if (error) {
          dispatch(LogOutOnAuthError(error))
        }
      })
  }

export default hsCodesReducer
