import Api from '../Api'

class SettingsApi extends Api {
        shippingRulesList(token, conditions) {
        return this.http.post('/settings/shipping_rules',
            {
                "limit": 10000,
                "offset": 0,
                "conditions": conditions || null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    shippingRulesDetails(token, shipping_rule_id) {
        return this.http.post('/settings/shipping_rule_conditions',
            {
                "limit": 100,
                "offset": 0,
                "shipping_rule_id": shipping_rule_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    shippingRulesDelete(token, shipping_rule_ids) {
        return this.http.delete('/settings/shipping_rules/delete',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data: {
                    "shipping_rule_ids": shipping_rule_ids
                }
            }
        )
    }
    shippingRulesCreate(token, rule_name, shop_id, country_id, status, shipping_rule_conditions) {
        return this.http.post('/settings/shipping_rules/create',
            {
                "shop_id": shop_id,
                "rule_name": rule_name,
                "country_id": country_id,
                "status": status,
                "shipping_rule_conditions": shipping_rule_conditions
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    shippingRulesEdit(token, rule_id, rule_name, shop_id, country_id, status, shipping_rule_conditions) {
        return this.http.post('/settings/shipping_rules/edit',
            {
                "rule_id": rule_id,
                "shop_id": shop_id,
                "rule_name": rule_name,
                "country_id": country_id,
                "status": status,
                "shipping_rule_conditions": shipping_rule_conditions
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    shippingRulesChangeStatus(token, status, shipping_rule_ids) {
        return this.http.post('/settings/shipping_rules/change_status',
            {
                "status": status,
                "shipping_rule_ids": shipping_rule_ids
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    customsDeclarationRulesList(token, conditions) {
        return this.http.post('/settings/customs_declaration_rules',
            {
                "limit": 10000,
                "offset": 0,
                "conditions": conditions || null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    customsDeclarationRulesChangeStatus(token, status, ids) {
        return this.http.post('/settings/customs_declaration_rules/change_status',
            {
                "status": status,
                "customs_declaration_rule_ids": ids
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    customsDeclarationRulesDelete(token, ids) {
        return this.http.delete('/settings/customs_declaration_rules/delete',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data: {
                    "customs_declaration_rule_ids": ids
                }
            }
        )
    }
    customsDeclarationRulesCreate(token, rule_name, shop_id, country_id, status, description,
                                  country_of_origin, quantity, item_cost, total_value_from, total_value_to,
                                  hs_code, hs_code_value, sku, sku_value, product_category) {
        return this.http.post('/settings/customs_declaration_rules/create',
            {
                "description": description,
                "country_id": country_id,
                "country_of_origin": country_of_origin,
                "product_category": product_category,
                "rule_name": rule_name,
                "quantity": quantity,
                "item_cost": item_cost,
                "total_value_from": total_value_from,
                "total_value_to": total_value_to,
                "hs_code": hs_code,
                "hs_code_value": hs_code_value,
                "sku": sku,
                "sku_value": sku_value,
                "status": status,
                "shop_id": shop_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    customsDeclarationRulesUpdate(token, id, rule_name, shop_id, country_id, status, description,
                                  country_of_origin, quantity, item_cost, total_value_from, total_value_to,
                                  hs_code, hs_code_value, sku, sku_value, product_category) {
        return this.http.post('/settings/customs_declaration_rules/update',
            {
                "id": id,
                "description": description,
                "country_id": country_id,
                "country_of_origin": country_of_origin,
                "product_category": product_category,
                "rule_name": rule_name,
                "quantity": quantity,
                "item_cost": item_cost,
                "total_value_from": total_value_from,
                "total_value_to": total_value_to,
                "hs_code": hs_code,
                "hs_code_value": hs_code_value,
                "sku": sku,
                "sku_value": sku_value,
                "status": status,
                "shop_id": shop_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getUploadingFilesList(token) {
        return this.http.get('order/uploading-personal-data/files',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getCarriersRatesList(token, company_id, rate_type_id, country_id) {
        return this.http.post('/settings/rates-new/list',
            {
                "company_id": company_id,
                "rate_type_id": rate_type_id,
                "country_id": country_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getCarriersCoefficientsLogList(token) {
        return this.http.get('carier-rates-upload-log',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getCarriersCoefficientsErrorLogList(token) {
        return this.http.get('carier-rates-upload-error',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getInvoicesList(token) {
        return this.http.post('/invoice/files-list',
            {
                "limit": 10000
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getInvoiceInvalidList(token, shop_ids, from, to) {
        return this.http.post('/invoice/list-invalid',
            {
                "conditions": {
                    "shop_ids": {
                        "filter": shop_ids,
                        "order_by": "ASC"
                    },
                    "invoice_date": {
                        "filter": {
                            "from": from,
                            "to": to
                        },
                        "order_by": "ASC"
                    }
                },
                "limit": 10000
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getInvalidInvoiceLink(token, parent_id) {
        return this.http.post('/invoice/invalid-excel',
            {
                "parent_id": parent_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    postInvoicePdf(token, shop_ids, date_from, date_to) {
        return this.http.post('/invoice/pdf',
            {
                "shop_ids": shop_ids,
                "date_from": date_from,
                "date_to": date_to
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    postInvoiceExcel(token, shop_ids, date_from, date_to) {
        return this.http.post('/invoice/excel',
            {
                "shop_ids": shop_ids,
                "date_from": date_from,
                "date_to": date_to
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    CreateCompanyAddress(token, company_name, company_address1, company_address2, company_address3, company_city, company_state, company_country, company_postal_code, company_phone) {
        return this.http.post('/company/address/create',
            {
                "company_name": company_name,
                "company_address1": company_address1,
                "company_address2": company_address2,
                "company_address3": company_address3,
                "company_city": company_city,
                "company_state": company_state,
                "company_country": company_country,
                "company_postal_code": company_postal_code,
                "company_phone": company_phone
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    EditCompanyAddress(token, address_id, company_name, company_address1, company_address2, company_address3, company_city, company_state, company_country, company_postal_code, company_phone) {
        return this.http.post('/company/address/update',
            {
                "address_id": address_id,
                "company_name": company_name,
                "company_address1": company_address1,
                "company_address2": company_address2,
                "company_address3": company_address3,
                "company_city": company_city,
                "company_state": company_state,
                "company_country": company_country,
                "company_postal_code": company_postal_code,
                "company_phone": company_phone
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getCompanyAddressList(token) {
        return this.http.post('/company/address/list',
            {
                "limit": 10000,
                "offset": 0,
                "conditions": null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    deleteCompanyAddress(token, address_id) {
        return this.http.delete('/company/address/delete',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data:  {
                    "address_id": address_id
                }
            }
        )
    }

    getConveyorLineGateRules({token}) {
        return this.http.get('/settings/conveyor_line_gate_rules',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }


    editConveyorLineGateRule({
        token,
        conveyor_lines_gate_rule_id,
        delivery_service_type_id,
        gate,
        priority,
        is_active
    }) {
        return this.http.post('/settings/conveyor_line_gate_rule/update', {
            conveyor_lines_gate_rule_id,
            delivery_service_type_id,
            gate,
            priority,
            is_active
        },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
               
            }
        )
    }

    createConveyorLineGateRule({
        token,
        delivery_service_type_id,
        gate,
        priority,
        is_active
    }) {
        return this.http.post('/settings/conveyor_line_gate_rule',
            {
                delivery_service_type_id,
                gate,
                priority,
                is_active
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getConveyorLineGateRule({
        token,
        conveyor_lines_gate_rule_id
    }) {
        return this.http.post('/settings/conveyor_line_gate_rule/show',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data:  {
                    conveyor_lines_gate_rule_id
                }
            }
        )
    }
    // https://alpha.canadamin.com/api/settings/conveyor_line_gate_rule/set-is-active

    setGateActive({
        token,
        gate,
        is_active
      }) {
        return this.http.post('/settings/conveyor_line_gate_rule/set-is-active',
            {
                gate,
                is_active
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
              
            })
      }

    deleteConveyorLineGateRule({
        token,
        conveyor_lines_gate_rule_id
    }) {
        return this.http.delete('/settings/conveyor_line_gate_rule',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data:  {
                     conveyor_lines_gate_rule_id
                }
            }
        )
    }

    createCompanyShopAddress(token, address_id, shop_id_list) {
        return this.http.post('/company/shop_address/add',
            {
                "address_id": address_id,
                "shop_id": shop_id_list
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    removeCompanyShopAddress(token, shop_id_list) {
        return this.http.delete('/company/shop_address/remove',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data: {
                    "shop_id": shop_id_list
                }
            }
        )
    }

    getCompanyShopAddressList(token, address_id, shop_id_list) {
        return this.http.post('/company/shop_address/list',
            {
                "limit": 10000,
                "offset": 0,
                "conditions": null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    updateCarriersRatesList(token, company_ids) {
        return this.http.post('/settings/rates-new/create',
            {
                "company_id": company_ids
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    uploadCarrierRatesError(token) {
        return  this.http.get('/settings/rates-new/upload-error', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    uploadCarrierRatesLog(token) {
        return  this.http.get('/settings/rates-new/upload-log', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    setCarrierRatesErrorIsViewed(token, rate_upload_error_id) {
        return this.http.post('/settings/rates-new/upload-error/set-is-viewed',
            {
                "rate_upload_error_id": rate_upload_error_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getShopCoefficientsList(token) {
        return  this.http.get('/shop-coef', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    editShopCoefficient(token, company_id, shops, carriers) {
        return this.http.post('/shop-coef/update',
            {
                "company_id": company_id,
                "shops": shops,
                "carriers": carriers
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    createShopCoefficient(token, company_id, shops, carriers) {
        return this.http.post('/shop-coef/create',
            {
                "company_id": company_id,
                "shops": shops,
                "carriers": carriers
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    deleteShopCoefficient(token, company_id, shops) {
        return this.http.delete('/shop-coef/delete',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data:  {
                    "company_id": company_id,
                    "shops": shops
                }
            }
        )
    }
    getSettingsHsCodes(token, table_id) {
        return  this.http.post('/settings/hs-code-category/list',
             {table_id},
             {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }

    getTableListHsCodes(token) {
        return  this.http.get('/settings/hs-code-category/table-list',
             {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    updateHsCodes(token, category_id, hs_code, table_id) {
        return this.http.post('/settings/hs-code-category/update',
            {
                "category_id": category_id,
                "table_id": table_id,
                "hs_code": hs_code
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getDimWeightList(token) {
            return  this.http.get('/dim-weight/list', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    updateDimWeight(token, delivery_service_type_id, dim_weight_coef) {
        return this.http.post('/dim-weight/update',
            {
                "delivery_service_type_id": delivery_service_type_id,
                "dim_weight_coef": dim_weight_coef
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    uploadReturnLabels(token, files_list) {
        return this.http.post('delivery/return/upload-label', files_list,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getReturnLabelsErrorLog(token) {
        return  this.http.get('delivery/return/upload-label-error', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    
    getReturnLabelsSuccessLog(token) {
        return  this.http.get('delivery/return/upload-label-success', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }

}

export default  new SettingsApi()